<template>
    <div>
        <card-header title="Edit Customer" icon="fa-pencil"/>

        <card-body>
            <customer-form :customer="customer"/>
        </card-body>

        <card-footer class="pt-3 pb-3">
            <div class="columns">
                <div class="column">
                    <b-button type="is-warning is-outlined" size="is-small" @click="deleteUser"><i class="far fa-trash-alt"></i><span class="ml-2" v-if="confirmDelete">Delete Customer?</span></b-button>
                </div>
                <div class="column has-text-right">
                    <b-button type="is-primary" size="is-small" :disabled="!customer.first_name || !customer.last_name || !customer.email || !customer.password" @click="submit"><i class="fas fa-check mr-3"></i>Save Customer</b-button>
                </div>
            </div>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from '../../TIER/components/CardHeader';
    import CardContainer from '../../TIER/components/CardContainer';

    import {client as http} from '../../http_client';
    import CardFooter from "../../TIER/components/CardFooter";
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";

    import CardBody from "../../TIER/components/CardBody";
    import ContainerListItem from "../../TIER/components/ContainerListItem";
    import CustomerForm from "@/cards/customers/CustomerForm";

    export default {
        props: ['card', 'props'],
        components: {CustomerForm, ContainerListItem, CardBody, SubheaderListItem, CardList, CardFooter, CardContainer, CardHeader},
        data: function () {
            return {
                customer: {},
                confirmDelete: false,
                dirty: null
            };
        },
        methods: {
            loadCustomer: function () {
                this.$emit('loading', true);
                http.get('/api/users/' + this.props.userId, {force: true}).then(response => {
                    this.customer = response.data;
                    this.$emit('loading', false);
                });
            },
            submit() {
                this.$emit('loading', true);
                http.put('/api/users/' + this.customer.id, this.customer, {force: true}).then(response => {
                    this.$reloadCard('customers');
                    this.$reloadCard('customer', {userId: this.props.userId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            },
            deleteUser() {
                if (this.confirmDelete) {
                    this.$emit('loading', true);

                    http.delete('/api/users/' + this.props.userId).then(response => {
                        this.$reloadCard('customers');
                        this.$reloadCard('customer', {userId: this.props.userId});
                        this.$saveAlert(false);
                        this.$closeCard(this.card);
                    }).catch(err => {

                    });
                } else {
                    this.confirmDelete = true;
                    setTimeout(() => {
                        this.confirmDelete = false;
                    }, 3000);
                }
            }
        },
        watch: {
            'props.userId': function () {
                this.dirty = null;
                this.loadCustomer();
            },
            'customer': {
                deep: true,
                handler: function () {
                    this.dirty = (this.dirty !== null);
                    if (!this.$TIER.saveAlert.active && this.dirty)
                        this.$saveAlert(true, 'Are you done editing this customer? Unsaved changes will be lost.');
                }
            }
        },
        mounted() {
            this.loadCustomer();
        }
    };
</script>
