<template>
    <card-list>
        <subheader-list-item icon="fa-align-left" title="Customer Information"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="First Name" label-position="inside">
                        <b-input v-model="customer.first_name"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Middle Name" label-position="inside">
                        <b-input v-model="customer.middle_name"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Last Name" label-position="inside">
                        <b-input v-model="customer.last_name"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Preferred Name" label-position="inside">
                        <b-input v-model="customer.preferred_name"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Title/Label" label-position="inside">
                        <b-input v-model="customer.title"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Gender" label-position="inside">
                        <b-select expanded v-model="customer.gender">
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Date of Birth" label-position="inside">
                        <b-input type="date" v-model="customer.dob"/>
                    </b-field>
                </div>
            </div>
        </container-list-item>
        <subheader-list-item icon="fa-sign-in-alt" title="Login Information"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="E-Mail Address" label-position="inside">
                        <b-input v-model="customer.email"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Password" label-position="inside">
                        <b-input v-model="customer.password"></b-input>
                    </b-field>
                </div>
            </div>
        </container-list-item>
        <subheader-list-item icon="fa-address-card" title="Contact Information"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="Phone" label-position="inside">
                        <b-input v-model="customer.phone"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Phone 2" label-position="inside">
                        <b-input v-model="customer.phone_2"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Address" label-position="inside">
                        <b-input v-model="customer.address"></b-input>
                    </b-field>
                </div>
                <div class="column is-4">
                    <b-field label="Address 2" label-position="inside">
                        <b-input v-model="customer.address_2"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column is-5">
                    <b-field label="City" label-position="inside">
                        <b-input v-model="customer.city"></b-input>
                    </b-field>
                </div>
                <div class="column is-4">
                    <b-field label="State" label-position="inside">
                        <b-select v-model="customer.state">
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b-field label="Zip" label-position="inside">
                        <b-input v-model="customer.zip"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column is-5">
                    <b-field label="Country" label-position="inside">
                        <b-input v-model="customer.country"></b-input>
                    </b-field>
                </div>
            </div>
        </container-list-item>
        <subheader-list-item icon="fa-align-left" title="Details"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="Tennis Level" label-position="inside">
                        <b-input v-model="customer.tennis_level"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Tennis Rating" label-position="inside">
                        <b-input v-model="customer.tennis_rating"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Tennis Club" label-position="inside">
                        <b-input v-model="customer.tennis_club"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Shirt Size" label-position="inside">
                        <b-input v-model="customer.shirt_size"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Dietary Requirements" label-position="inside">
                        <b-input type="textarea" v-model="customer.dietary_requirements"></b-input>
                    </b-field>
                </div>
            </div>
        </container-list-item>
        <subheader-list-item icon="fa-align-left" title="Authorize.net"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="Customer Profile ID" label-position="inside" message="Warning: Changing the id must match records stored on Authorize.net or payments will fail.  This ID will be used if a new card is added.  Payment method may override this ID.">
                        <b-input v-model="customer.authnet_customer_profile_id"></b-input>
                    </b-field>
                </div>
            </div>
        </container-list-item>
    </card-list>
</template>

<script>
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";

    export default {
        name: 'CustomerForm',
        props: {
            customer: {
                type: Object,
                required: true
            }
        },
        components: {ContainerListItem, SubheaderListItem, CardList}
    };

</script>

<style scoped>

</style>
